import {Container, Navbar, Nav} from "react-bootstrap";

export const Header = () => {
    return (
        <Navbar bg="light" data-bs-theme="light" expand={'lg'} collapseOnSelect>
            <Container fluid={false}>
                <Navbar.Brand href="#home">
                    René Ponce
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className={'justify-content-end flex-grow-1 pe-3'}>
                        <Nav.Link href="#home">Home</Nav.Link>
                        <Nav.Link href="#about">About</Nav.Link>
                        <Nav.Link href="#projects">Projects</Nav.Link>
                        <Nav.Link href="#contact">Contact</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}