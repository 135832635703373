import {Col, Container, Row} from "react-bootstrap";
import logo from "../../assets/img/logo.png";

export const Home = () => {
    return (
        <Container>
            <Row>
                <Col xl={12} lg={12} md={12} sm={12} className={'d-flex justify-content-center'}>
                    <img src={logo} alt={'logo'} className={'logo mt-3'} />
                </Col>
            </Row>
            <Row>
                <Col xl={12} lg={12} md={12} sm={12} className={'d-flex justify-content-center'}>
                    <h1 className={'mt-3'}>René Ponce</h1>
                </Col>
            </Row>
        </Container>
    )
}